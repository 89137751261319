"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsBenutzerColumnsDefs = void 0;
const authStore_1 = require("@/state/authStore");
const usersStore_1 = require("@/state/usersStore");
const common_ui_1 = require("@rose/common-ui");
function settingsBenutzerColumnsDefs() {
    return [
        {
            headerName: 'Bild',
            cellRenderer: 'AvatarCell',
            sortable: false,
            width: 70,
        },
        {
            headerName: 'Name',
            field: 'lastName',
            valueGetter: params => { var _a, _b; return `${(_a = params.data) === null || _a === void 0 ? void 0 : _a.firstName} ${(_b = params.data) === null || _b === void 0 ? void 0 : _b.lastName}`; },
            sortable: true,
            sort: 'asc',
            width: 200,
        },
        {
            headerName: 'Letzte Aktivität',
            field: 'lastActivity',
            valueFormatter: params => {
                var _a;
                return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.lastActivity)
                    ? common_ui_1.humanizeDiffFilter.filters.humanizeFromNow(params.data.lastActivity, true)
                    : 'Nie angemeldet';
            },
            sortable: true,
            width: 120,
        },
        {
            headerName: 'E-Mail',
            field: 'email',
            cellRenderer: 'CopyCell',
            width: 300,
            sortable: true,
        },
        {
            headerName: 'Ext. Benutzer',
            headerTooltip: 'Externer Benutzer',
            valueGetter: params => { var _a; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.mainclientid) !== authStore_1.authStore.getters.cid; },
            cellRenderer: 'ExternalAccessCell',
            cellStyle: { textAlign: 'center', 'justify-content': 'center' },
            width: 120,
        },
        {
            headerName: 'Ext. Zugriff',
            headerTooltip: 'Externer Zugriff',
            valueGetter: params => { var _a, _b; return !((_b = (_a = params.data) === null || _a === void 0 ? void 0 : _a.ipfiltering) === null || _b === void 0 ? void 0 : _b.active); },
            cellRenderer: 'CheckAndCrossCell',
            cellRendererParams: {
                context: {
                    colorized: true,
                },
            },
            cellStyle: { textAlign: 'center', 'justify-content': 'center' },
            width: 120,
        },
        {
            headerName: 'Zwei-Faktor-Login',
            cellRenderer: 'TwoFaCell',
        },
        {
            headerName: 'Erlaubte Views',
            cellRenderer: 'UserViewsCell',
            flex: 1,
            autoHeight: true,
            minWidth: 200,
        },
        {
            headerName: '',
            field: 'id',
            cellRenderer: 'ActionButtonsCell',
            width: 110,
            sortable: false,
            cellRendererParams: {
                context: {
                    edit(user) {
                        usersStore_1.usersStore.commit.setSelectedUser(user);
                        usersStore_1.usersStore.commit.setShowModal(true);
                    },
                    clone(user) {
                        usersStore_1.usersStore.commit.setDuplicateUser(true);
                        usersStore_1.usersStore.commit.duplicateUser(user);
                        usersStore_1.usersStore.commit.setShowModal(true);
                    },
                    delete(user) {
                        usersStore_1.usersStore.commit.setSelectedUser(user);
                        usersStore_1.usersStore.commit.setShowDeleteDialog(true);
                    },
                },
            },
        },
    ];
}
exports.settingsBenutzerColumnsDefs = settingsBenutzerColumnsDefs;
