"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  props: ['title', 'text', 'cancelButton', 'proceedButton'],
  data: function data() {
    return {};
  },
  methods: {
    closeDialog: function closeDialog(value) {
      this.$emit('dialog-response', value);
    }
  }
});