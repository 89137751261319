/* unplugin-vue-components disabled */import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"text-h5 lighten-2"},[_vm._v(_vm._s(_vm.title))]),_c(VCardText,{staticClass:"pt-4"},[_vm._v(_vm._s(_vm.text))]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('div',{staticClass:"d-flex"},[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeDialog(false)}}},[_vm._v(_vm._s(_vm.cancelButton))]),_c(VBtn,{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.closeDialog(true)}}},[_vm._v(_vm._s(_vm.proceedButton))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }